:root {
  /* Define global variables */
  --gree-color: #88b04b;
  --gray-color: #ccc;
}

.searchss {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1%;
  border-radius: 28px;
  background: white;
  transition: box-shadow 0.25s;
}
.searchsss {
  width: 70%;
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 28px;
  background-color: white;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Outer shadow */
  background-color: #f0f0f0;
}
.widtth_main {
  width: 65%;
}
.searchs_in_scroll {
  width: 60%;
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 28px;
  background-color: white;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Outer shadow */
  background-color: #f0f0f0;
}

.screachss:focus-within {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.75);
}

.search-inputs {
  font-size: 16px;
  color: black;
  margin-left: 14px;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  flex: 1;
}

.search-inputs::placeholder,
.screach-icons {
  color: rgb(41, 39, 39);
}
.margin_bottom {
  margin-bottom: 7%;
}

.mainh_head {
  font-weight: 800;
  text-align: center;
}

.backgroundimage {
  background: linear-gradient(to right, #12100e, #2b4162);
  color: #ffffff; /* White text color for good contrast */
}

.height_width {
  margin-bottom: 3%;
  padding-bottom: 2.5%;
}

.text-whites {
  color: white !important;
}

/* buttons section */
.back_icones {
  background-color: #23344d; /* Slightly lighter shade of #12100E */
  padding: 8px 17px;
  border-radius: 10px;
  color: #f5f5f5; /* Light gray for better contrast */
  border: none;
  outline: none;
  border: #1d2e48 1px solid;
}
.scrollback_icons {
  background-color: white; /* Slightly lighter shade of #12100E */
  padding: 6px 17px;
  border-radius: 10px;
  color: #23344d; /* Light gray for better contrast */
  border: none;
  outline: none;

  border: #1d2e48 1px solid; /* Example box shadow */
}

.icone_sixe_backgroun {
  font-size: 20px;
}

.margin_btoom_trending {
  margin-bottom: 3%;
}

.activesss {
  background-color: var(
    --gree-color
  ) !important; /* Change this to your desired active background color */
  border-radius: 10px; /* Adjust this to your desired border radius ** Inner shadow when active */
}
.scroll_activesss {
  background-color: #23344d !important; /* Change this to your desired active background color */
  border-radius: 10px;
}

.activetext {
  color: #ffffff !important; /* Change this to your desired active text color */
}

.icone_sixe_backgroun {
  font-size: 23px; /* Adjust the icon size */
}

.text_icon_font {
  font-size: 15px; /* Adjust the text size */
}

.mainh_head {
  height: 70px;
}

/* card */
.text_icon_font {
  font-weight: 600;
}

.text_icon_fontss {
  color: white;
  font-weight: 600;
  font-size: 14px !important;
}

.image_logo_card {
  border-radius: 4px;
  height: 20px;
  width: 20px;
}

.font_size_chanlename {
  font-size: 15px;
}

.font_size_titlename {
  font-size: 18px;
  font-weight: 600;
}

.font_size_lectrename {
  font-size: 13px;
}

.green_free {
  padding: 3% 5% !important;
  border-radius: 10px;
  background: #4d6993; /* Green for free courses */
  transition: box-shadow 0.25s;
  padding: 3% 5%;
}

.blue_free {
  padding: 3% 5% !important;
  border-radius: 10px;
  background-color: #1d2e48; /* Blue for premium courses */
  transition: box-shadow 0.25s;
  padding: 3% 5%;
}

/* Add transition and hover effect for the card */
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.white-icon {
  color: white;
  fill: white;
  font-size: 13px;
}

.activesss {
  background-color: #889ebc; /* Slightly lighter shade of #2B4162 */
}
.activetext {
  color: #090807;
}

.btn_color {
  background-color: white !important; /* Bright orange-yellow */
  color: #2b4162 !important; /* Black text */
  border: 2px solid #2b4162 !important; /* Example: 2px solid black border */
  font-weight: 700;
  font-size: 14px;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Adjust as needed to ensure the navbar stays on top */
}

/* Add this to your existing Navbar.css */

/* Add this to your existing Navbar.css */
/* Add this to your existing Navbar.css */
.fixed-navbar {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s,
    padding 0.2s;
  font-weight: 500;
}

.fixed-navbar.scrolled {
  background-color: white;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.fixed-navbar.scrolled .nav-link,
.fixed-navbar.scrolled .navbar-brand {
  color: black !important;
  font-weight: 500;
}

.searchsss {
}
.search-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  background-color: #2196f3;
  margin: 1px;
}

.search-input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.screach-icons {
  margin-right: 10px;
}

/* anite */

/* ScreachSection.css */
.mainh_head {
  font-size: 2.5rem;
  font-weight: bold;
}

.margin_bottom_icons {
  margin-top: 5%;
}

/* Add these styles to your existing CSS file */
.text-whitesss {
  color: white !important;
}
.nav-linksss {
  text-decoration: none;
  font-weight: 500;
}
.gap_4 {
  gap: 7px;
}
.back_contuse {
  background-color: var(--gree-color);
  border-radius: 10px;
  color: #f5f5f5; /* Light gray for better contrast */
  border: none;
  outline: none;
}
.nav-linksss_pading {
  padding: 4px 10px;
}
.font_weigth_navbar_main {
  font-weight: 700;
}
/* styles.css */
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.custom-font-style {
  font-family: "Lobster", cursive;
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: normal;
  color: #ffffff; /* Adjust the text color as needed */
}
.custom-font-style_black {
  font-family: "Lobster", cursive;
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: normal;
  color: black; /* Adjust the text color as needed */
}

.highlight-yellow {
  color: yellow;
}

.main_head {
  font-size: 30px;
  font-weight: 600;
}

/* discover */

.btn_color_dicover {
  background-color: var(--gree-color);
}
.back_contusess {
  background-color: var(--gree-color);
  border-radius: 10px;
  color: #f5f5f5; /* Light gray for better contrast */
  border: none;
  outline: none;
  padding: 1% 2%;
  transition: background-color 0.3s, color 0.3s, border 0.3s; /* Transition for background color, text color, and border */
}

.back_contusess:hover {
  background-color: #fff; /* White background on hover */
  color: var(--gree-color);
  border: 1px solid var(--gree-color); /* Change text color to the green color */
}

/* suggestion */

.react_button_suggest {
  /* Bright orange-yellow */
  color: #2b4162 !important;
  border: 2px solid #ceced0 !important;
  font-weight: 600;
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
  height: 100%;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px; /* Adjust the value as needed */
  margin-left: 10px; /* Adjust the value as needed */ /* Adjust the value as needed */
  text-align: center;
  background-color: white; /* Adjust the value as needed */
}
.suggestion_margin {
  margin-bottom: 1%;
}

.fire_trending {
  color: var(--gree-color) !important;
  position: relative; /* Adding position relative */
  margin-bottom: 7px;
  font-size: 20px; /* Adding padding at the bottom */
}

.styling_buttons {
  z-index: 1000;
}

/*about navbar */

.backgroundimage_about {
  padding: 10px 0;

  font-weight: 500;
}
/* aboutsection */

.text_size_main {
  font-size: 40px;
  font-weight: 500;
}
.background_blue {
  background: linear-gradient(to right, #12100e, #2b4162);
  color: #ffffff;
  height: 400px;

  padding-left: 10px;
  padding-right: 10px;
}
.background_blue_section {
  background: #354e74;
  color: #ffffff;
  border-radius: 40px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.background_blue_sectionss {
  border-radius: 10px;
  border: #848a92 solid;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.box_shadow_foucs {
  box-shadow: 0px 2px 4px #12100e;
}

.background_gradient {
  background: linear-gradient(to right, #12100e, #2b4162);
}

.circual_background {
  background-color: var(--gree-color);
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: flex;
  color: #12100e !important;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Center horizontally */
}

.font_size_achievmenticon {
  font-size: 60px;
  color: white !important;
}

.margin_bottom_aboutourmission {
  margin-bottom: 5%;
  margin-top: 1%;
}
.margin_bottom_aboutourmissionsecond {
  margin-bottom: 5%;
  margin-top: 7%;
}

.font_below_icon {
  font-size: 28px;
  margin-top: 3%;
  text-align: center;
  font-weight: 600;
  color: #354e74 !important;
}
.font_titke_achievment {
  font-size: 40px;
  margin-top: 3%;
  text-align: center;
}

.font_below_iconsss {
  border: 0;
  height: 2px;
  background: #12100e;
  margin-top: 3%;
}

.margin_top_container {
  margin-top: 3%;
}
.submitbuttong_contactus {
  background-color: #acbfdd;
  color: #3b4f71;
  border: none;
  font-weight: 700 !important;
}
.font_weight_buttoncontuse {
  font-weight: 500;
}
.font_contant_us {
  font-size: 25px;
  font-weight: 600;
}
.geern_var {
  color: var(--gree-color);
}
.white_var {
  color: white;
}
.color_blue_one {
  color: #354e74 !important;
}

.background_bluess {
  background: linear-gradient(to right, #12100e, #2b4162);
  color: #ffffff;
  height: 300px;

  padding-left: 10px;
  padding-right: 10px;
}
.font_size_ourmission_para {
  font-size: 20px;
}

.hidden {
  opacity: 0;
  transition: all 2s;
}
.show {
  opacity: 1;
}
.margin_top_work {
  margin-top: 20px;
}
.gap_between {
  display: flex;
  flex-direction: column;
  gap: 40px !important;
}

.font_wiegth_below_icon {
  font-weight: 600;
  font-size: 25px;
}

.background_blue_section {
  transition: transform 0.3s, box-shadow 0.3s;
}

.background_blue_section:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.font_near_the_icon {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.font_near_the_icon_graducaion {
  font-size: 24px;
}
.font_near_the_text_graducaion {
  font-size: 25px;
  font-weight: 400;
}

.font_awson_vision {
  font-size: 70px;
  margin-bottom: 10%;
  color: #354e74;
}
.font_titke_achievment {
  font-size: 40px;
  font-weight: 600;
  color: #354e74;
}
.font_color_green {
  color: var(--gree-color);
}
.our_vision_big_text {
  font-weight: 500;
  font-size: 40px;
}
.backgroun_grey_one {
  background-color: rgb(233, 233, 233);
}

/* Custom styles for the slick slider buttons */
/* Trending.css */

.slick-prev,
.slick-next {
  width: 110px;
  height: 110px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 55px;
  color: #1d2e48 !important;
}

.card_weight {
  width: 100%;
}

.img_width_aboutsus {
  width: 90%;
}

.width_container_contactuse {
  width: 100%;
}
.card_weight_lectursection {
  width: 100%;
}

.justify-content_between {
  justify-content: space-between;
}

.navbar-toggled {
  background-color: white;
}

.text_color_navbar_scrolled {
  color: #354e74 !important;
}

.navbar-toggled .nav-link,
.navbar-toggled .navbar-brand,
.navbar-toggled .fa-envelope {
  color: #354e74 !important;
}

.navbar-toggled {
  background-color: white;
}

.text_color_navbar_scrolled {
  color: #354e74 !important;
}

.navbar-toggled .nav-link,
.navbar-toggled .navbar-brand,
.navbar-toggled .fa-envelope {
  color: #354e74 !important;
}

.font_below_icon_proffesionals {
  font-size: 26px;
  margin-top: 3%;
  text-align: center;
  font-weight: 600;
  color: #354e74 !important;
}
.image_ourprofeitoinl {
}
.font_wiegth_below_icon_proffion {
  font-size: 16px;
  font-weight: 500;
}

.font_size_achievmenticonss {
  color: white !important;
}
/* Add to your CSS file or a style block */

/* Add these styles to your CSS file */

/* Default styles */

/* Responsive styles */
/* Default styles */
/* Responsive styles */

/* Existing CSS here */

/* Media query for max width 770px */
@media (max-width: 760px) {
  .scrolled-icons .text_icon_font {
    display: none !important;
  }
  .scrollback_icons {
    background-color: white; /* Slightly lighter shade of #12100E */
    padding: 1px 7px !important;
    border-radius: 10px;
    color: #23344d; /* Light gray for better contrast */
    border: none;
    outline: none;

    border: #1d2e48 1px solid; /* Example box shadow */
  }
  .searchs_in_scroll {
    display: none !important;
  }
  .widtth_main {
    width: 30% !important;
  }
  .padding_top {
    padding-top: 12%;
  }
  .mainh_head {
    font-size: 30px !important;
    font-weight: 800;
    text-align: center;
  }
  .parasecerachsection_size {
    font-size: 12px;
  }
  .gap_4 {
    gap: 1px;
  }
  .back_contuse {
    background-color: var(--gree-color);
    border-radius: 10px;
    color: #f5f5f5; /* Light gray for better contrast */
    border: none;
    outline: none;
    width: 50%;
  }
  .width_container_contactuse {
    width: 100%;
  }

  .font_size_titlename {
    font-size: 16px;
    font-weight: 700;
  }
  .font_size_chanlename {
    font-size: 11px;
  }
  .font_size_lectrename {
    font-size: 11px;
  }
  .text_size_main {
    font-size: 37px;
    font-weight: 500;
  }
  .background_bluess {
    background: linear-gradient(to bottom, #12100e, #2b4162);
    color: #ffffff;
    height: 200px;

    padding-left: 10px;
    padding-right: 10px;
  }
  .height_width {
    margin-bottom: 8%;
    padding-bottom: 5.5%;
  }
}

@media (max-width: 1020px) and (min-width: 760px) {
  .scrolled-icons .text_icon_font {
    display: none !important;
  }
  .scrolled-icons {
    margin-top: 5px;
  }

  .widtth_main {
    width: 70% !important;
  }
  .height_width {
    margin-bottom: 8%;
    padding-bottom: 5.5%;
}

  .sreach_background {
    width: 60%;
  }

  .mainh_head {
    font-size: 30px !important;
    font-weight: 800;
    text-align: center;
  }
  .parasecerachsection_size {
    font-size: 12px;
  }
  .gap_4 {
    gap: 1px;
  }

  .font_size_titlename {
    font-size: 1px;
    font-weight: 700;
  }
  .font_size_chanlename {
    font-size: 11px;
  }
  .font_size_lectrename {
    font-size: 11px;
  }
  .justify-content_between {
    justify-content: space-around;
  }
}

@media (max-width: 1100px) and (min-width: 1020px) {
  .scrolled-icons .text_icon_font {
    display: none !important;
  }
  .scrolled-icons {
    margin-top: 5px;
  }

  .widtth_main {
    width: 60% !important;
  }

  .sreach_background {
    width: 60%;
  }

  .mainh_head {
    font-size: 30px !important;
    font-weight: 800;
    text-align: center;
  }
  .parasecerachsection_size {
    font-size: 12px;
  }
  .gap_4 {
    gap: 1px;
  }

  .font_size_titlename {
    font-size: 1px;
    font-weight: 700;
  }
  .font_size_chanlename {
    font-size: 11px;
  }
  .font_size_lectrename {
    font-size: 11px;
  }
  .justify-content_between {
    justify-content: space-around;
  }
}
